import {defineStore} from 'pinia'

import {isTest} from '@/utils/isTest'
import {nuApiUrl} from '@/utils/nuApiUrl'

export const useAccountStore = defineStore({
  id: 'account',
  actions: {
    async registerUser(payload) {
      try {
        const apiUrl = 'account/register'
        if (isTest()) {
          return getUnitTestDataForStore(apiUrl)
        }
        const url = `${useRuntimeConfig().public.apiBaseUrl}/${apiUrl}`

        const response = await useAsyncData(
          'register-user',
          async () =>
            await $authFetch(url, {
              method: 'POST',
              body: payload,
            }),
        )
        return response
      } catch (error) {
        console.error('Error', error)
      }
    },

    async checkoutAsGuestUser(payload) {
      try {
        const apiUrl = 'account/guest'
        if (isTest()) {
          return getUnitTestDataForStore(apiUrl)
        }
        const url = `${useRuntimeConfig().public.apiBaseUrl}/${apiUrl}`

        const response = await useAsyncData(
          'register-guest-user',
          async () =>
            await $authFetch(url, {
              method: 'POST',
              body: payload,
            }),
        )

        return response
      } catch (error) {
        console.error('Error', error)
      }
    },

    async resetPassword(payload) {
      try {
        const apiUrl = 'account/reset-password'
        if (isTest()) {
          return getUnitTestDataForStore(apiUrl)
        }

        const IS_SSR = useNuxtApp().ssrContext

        const response = await useAsyncData('reset-password', async () => {
          const response = await $authFetch(nuApiUrl(apiUrl, IS_SSR), {
            method: 'POST',
            body: payload,
          })

          return response
        })
        return response
      } catch (error) {
        console.error('Error', error)
      }
    },

    async confirmPassword(payload) {
      try {
        const apiUrl = 'account/reset-password/confirm'
        if (isTest()) {
          return getUnitTestDataForStore(apiUrl)
        }

        const IS_SSR = useNuxtApp().ssrContext

        const response = await useAsyncData('confirm-reset-password', async () => {
          const response = await $authFetch(nuApiUrl(apiUrl, IS_SSR), {
            method: 'POST',
            body: payload,
          })

          return response
        })
        return response
      } catch (error) {
        console.error('Error', error)
      }
    },
  },
})
